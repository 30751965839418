import { FieldArrayPath, useFieldArray, useFormState, useWatch } from "react-hook-form";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import SelectInput from '@/components/FormInputs/SelectInput';
import FormattedInput from "@/components/FormInputs/FormattedInput.tsx";
import TextInput from "@/components/FormInputs/TextInput.tsx";
import { InputAdornment, useMediaQuery, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Fragment } from "react";
import { StudentInfoFormValues } from "@/components/users/Students/Account/Tabs/Profile/formSchema";
import { PhoneTypes } from "@/components/Forms/Constants";

interface PropTypes {
    name: FieldArrayPath<StudentInfoFormValues>;
}

function PhoneFieldArray({ name }: PropTypes) {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });
    const fieldArray = useFieldArray({ name: name });
    const phoneValues = useWatch({ name: name });
    const { errors } = useFormState<StudentInfoFormValues>({
        name: name
    });

    return (
        <Grid container rowSpacing={2} columnSpacing={isMd ? 4 : 2}>
            {fieldArray.fields.map((_field, index) => (
                <Fragment key={index}>

                    <Grid item xs={12}>
                        <Typography
                            variant='h5'
                            sx={{
                                fontWeight: 'bold',
                                textDecoration: 'underline'
                            }}
                        >
                            Phone Number {index + 1}
                        </Typography>
                        <Typography color="error" fontWeight="bold">
                            {errors[name]?.[index]?.root?.message}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <SelectInput
                            name={`${name}.${index}.type`}
                            label="Type"
                            options={PhoneTypes}
                            sx={{
                                minWidth: '100px'
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <FormattedInput
                            name={`${name}.${index}.phone`}
                            label="Phone Number"
                            format="(###) ###-####"
                            mask="_"
                        />
                    </Grid>

                    {phoneValues?.[index]?.type === 'work' && (
                        <Grid item xs={12} md={4}>
                            <TextInput
                                name={`${name}.${index}.extension`}
                                label="Extension"
                                InputProps={{ startAdornment: <InputAdornment position="start">Ext.</InputAdornment> }}
                            />
                        </Grid>
                    )}

                    {index > 0 && (
                        <Grid item xs={12} md={phoneValues?.[index]?.type === 'work' ? 2 : 6} alignSelf="start" mt={isMd ? 4.5 : 0}>
                            <Button
                                variant="contained"
                                size="small"
                                color="error"
                                fullWidth={!isMd}
                                onClick={() => {
                                    console.log(`Deleting index: ${index}`);
                                    fieldArray.remove(index)
                                }}
                            >
                                Remove Phone {index + 1}
                            </Button>
                        </Grid>
                    )}

                </Fragment>
            ))}

            {fieldArray.fields.length === 0 && (
                <Grid item xs={12}>
                    <Typography
                        variant='h5'
                        sx={{
                            fontWeight: 'bold',
                            textDecoration: 'underline'
                        }}
                    >
                        No Phone Numbers
                    </Typography>
                </Grid>
            )}

            <Grid item xs={12}>
                <Button variant="contained" size="small" fullWidth onClick={() => {
                    fieldArray.append({
                        priority: fieldArray.fields.length + 1,
                        type: undefined,
                        phone: '',
                        extension: '',
                    });
                }}>Add Phone Number</Button>
            </Grid>

        </Grid>
    );
}

export default PhoneFieldArray;