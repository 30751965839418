import useAuth from "@/context/useAuth";
import { AccessTokenPayload } from "context/AuthContext";
import { Navigate, Outlet, useLocation } from "react-router-dom";

interface PropTypes {
    allowRoles: Array<AccessTokenPayload['role']>;
}

function RequireAuth({ allowRoles }: PropTypes) {
    const { auth } = useAuth();
    const location = useLocation();

    // verify there is an access token
    if (!auth?.accessToken) {
        return <Navigate to="/auth/sign-in" state={{ from: location }} replace />;
    }

    // get the users role from the token payload
    const userRole = JSON.parse(atob(auth.accessToken?.split('.')?.[1]))?.role;


    if (userRole && allowRoles.includes(userRole)) {
        console.log('Logged in')
        return <Outlet />;
    }

    // todo make unauthorized page
    return <Navigate to="/auth/sign-in" state={{ from: location }} replace />;

}

export default RequireAuth;