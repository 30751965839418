import { publicApi } from "@/api/api";
import axios from "axios";
import { Dispatch, SetStateAction, createContext, useEffect, useState } from "react";

interface PropTypes {
    children: JSX.Element
}

interface LogoutOptions {
    message: string;
    level: 'success' | 'info' | 'warning' | 'error'
}

interface AccessTokenPayload {
    tenantId: string;
    userId: string;
    role: 'ADMIN' | 'INSTRUCTOR' | 'STUDENT';
    issueMethod: 'LOGIN' | 'REFRESH_TOKEN';
    iat: number;
    exp: number;
}

interface AuthContextData {
    accessToken?: string;
    user?: {
        id: string;
        role: 'ADMIN' | 'INSTRUCTOR' | 'STUDENT';
    }
}

interface IAuthContext {
    auth: AuthContextData;
    setAuth: Dispatch<SetStateAction<AuthContextData>>;
    logout: (options?: LogoutOptions) => Promise<void>;
    persist: boolean;
}

const AuthContext = createContext<IAuthContext>({
    auth: {},
    setAuth: () => { },
    logout: async (options?: LogoutOptions) => { },
    persist: false,
});

function AuthProvider({ children }: PropTypes) {
    const [auth, setAuth] = useState<AuthContextData>({});
    // const [persist, setPersist] = useState(JSON.parse(localStorage.getItem("persist")) || false);

    useEffect(() => {
        console.log(`Auth changed to: ${JSON.stringify(auth, null, 2)}`);
    }, [auth]);

    const logout = async (options?: LogoutOptions) => {
        try {
            // make logout request
            const response = await publicApi.get('/public/auth/logout');

            console.log(JSON.stringify(response?.data, null, 2));

            // update auth context
            setAuth({});

            if ('logoutRedirectURI' in response.data) {
                if (options) {
                    window.location.replace(`${response?.data?.logoutRedirectURI}?message=${options.message}&level=${options.level}`);
                } else {
                    window.location.replace(response?.data?.logoutRedirectURI);
                }
            }
        } catch (error) {
            // if an axios error was thrown
            if (axios.isAxiosError(error)) {
                if (!error?.response) { // no response from the server
                    alert("No Server Response");
                } else if (error.response?.data?.message) { // incorrect username or password
                    alert(error.response?.data?.message);
                }
            }
        }
    };

    return (
        <AuthContext.Provider value={{ auth, setAuth, logout, persist: true }}>
            {children}
        </AuthContext.Provider>
    );
}

export type { AuthContextData, AccessTokenPayload };
export { AuthContext, AuthProvider };