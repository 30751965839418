import phone from "phone";
import { z } from "zod";

const phoneSchema = z.object({
    priority: z.number().positive().readonly(),
    type: z.enum(['mobile', 'home', 'work', 'fax', 'other'], {
        required_error: 'Phone type is required'
    }),
    phone: z.string()
        .min(1, "Phone number is required")
        .refine(value => !value || phone(value).isValid, {
            message: 'Phone number is invalid',
        }),
    extension: z.string(),
}).refine(value => {
    if (value.type !== 'work') {
        // remove extension when type is not 'work'
        value.extension = '';
    }
    return true;
});

export type PhoneSchemaValues = z.infer<typeof phoneSchema>;
export default phoneSchema;