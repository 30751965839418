import * as React from 'react';
import Box from '@mui/material/Box';
import { GridSlotsComponentsProps } from '@mui/x-data-grid';
import Typography from "@mui/material/Typography";


declare module '@mui/x-data-grid' {
    interface FooterPropsOverrides {
        balance: string;
    }
}

export function CustomFooterStatusComponent(
    props: NonNullable<GridSlotsComponentsProps['footer']>,
) {
    return (
        <Box sx={{ pr: 4, pb: 1, display: 'flex', justifyContent: 'flex-end' }}>
            <Typography>Balance: {props.balance}</Typography>
        </Box>
    );
}
