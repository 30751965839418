import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import MasterCalendarV3 from "@/components/scheduling/Master-CalendarV3";

const sidebarRoutes = [
  {
    type: 'collapse',
    name: 'Scheduling',
    key: 'scheduling',
    icon: <CalendarTodayIcon/>,
    collapse: [
      {
        name: 'Master Calendar',
        key: 'calendar',
        route: 'scheduling/master-calendar',
        component: MasterCalendarV3,
      },
    ],
  },
];

export default sidebarRoutes;
