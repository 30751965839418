import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from "zod";
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import useAuth from '@/context/useAuth';
import useRefreshToken from '@/context/useRefreshToken';
import { publicApi } from '@/api/api';
import TextInput from '@/components/Forms/TextInput';
import { jwtDecode } from 'jwt-decode';
import { AccessTokenPayload } from '@/context/AuthContext';

const formSchema = z.object({
  username: z.string(),
  password: z.string(),
});

type FormValues = z.infer<typeof formSchema>;

function Form(): JSX.Element {
  const { auth, setAuth } = useAuth();
  const refresh = useRefreshToken();
  const navigate = useNavigate();
  const location = useLocation();


  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      username: '',
      password: '',
    }
  });

  const { handleSubmit } = form;

  useEffect(() => {
    if (auth?.accessToken) {
      // get the users role from the access token payload
      const role = JSON.parse(atob(auth?.accessToken.split('.')?.[1]))?.role;

      // if the user was redirected to the login page, redirect them back
      if (location.state?.from?.pathname && location.pathname !== location.state?.from?.pathname) {
        navigate(location.state?.from?.pathname, { replace: true })
      }

      // redirect the user based on their role
      if (role === 'ADMIN') {
        // alert('Admin login not supported yet');
        // await logout();
        navigate('/admin');
      } else if (role === 'INSTRUCTOR') {
        // alert('Instructor login not supported yet');
        // logout();
        navigate('/instructor');
      } else if (role === 'STUDENT') {
        navigate('/student');
      }
    } else {
      refresh();
    }
  }, [auth]);


  const onSubmit = async ({ username, password }: FormValues) => {
    try {
      // make login request
      const response = await publicApi.post('/public/auth/login', { username, password });

      console.log(JSON.stringify(response?.data, null, 2));

      // verify response has an access token and role
      if (!response?.data?.accessToken) {
        alert('Server response missing access token');
        return;
      }

      // get access token and role from response
      const { accessToken } = response.data;

      // get data from access token
      const { userId, role } = jwtDecode<AccessTokenPayload>(accessToken);

      // update auth context
      setAuth({
        accessToken, user: {
          id: userId,
          role: role,
        }
      });

      // redirect user to proper page based on role
      // redirect(accessToken);
    } catch (error) {
      // if an axios error was thrown
      if (axios.isAxiosError(error)) {
        if (!error?.response) { // no response from the server
          alert("No Server Response");
        } else if (error.response?.data?.message) { // incorrect username or password
          alert(error.response?.data?.message);
        }
      }
    }

    // await publicApi.post('/auth/login', { username, password })

    // mutateAsync({
    //   username: values.username,
    //   password: values.password
    // }).then((result) => {
    //   console.log(JSON.stringify(result, null, 2));
    //   alert('Success!!!');
    // }).catch((error) => {
    //   console.log(error);
    //   alert(error.response.data.message ?? error.message)
    // })

    // return values;
  };

  return (
    <div>
      You have been logged out.
    </div>
  );

  // return (
  //   <FormProvider {...form}>
  //     <Box>
  //       <Box marginBottom={4}>
  //         <Typography
  //           sx={{
  //             textTransform: 'uppercase',
  //             fontWeight: 'medium',
  //           }}
  //           gutterBottom
  //           color={'text.secondary'}
  //         >
  //           Login
  //         </Typography>
  //         <Typography
  //           variant="h4"
  //           sx={{
  //             fontWeight: 700,
  //           }}
  //         >
  //           Welcome back
  //         </Typography>
  //         <Typography color="text.secondary">
  //           Login to manage your account.
  //         </Typography>
  //       </Box>
  //       <form onSubmit={handleSubmit(onSubmit)}>
  //         <Grid container spacing={4}>
  //           <Grid item xs={12}>
  //             {/* <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
  //               Enter your email
  //             </Typography> */}
  //             {/* <TextField
  //               label="Email *"
  //               variant="outlined"
  //               name={'email'}
  //               fullWidth
  //               value={formik.values.email}
  //               onChange={formik.handleChange}
  //               error={formik.touched.email && Boolean(formik.errors.email)}
  //               helperText={formik.touched.email && formik.errors.email}
  //             /> */}
  //             <TextInput name="username" label="Email" />
  //           </Grid>
  //           <Grid item xs={12}>
  //             <Box
  //               display="flex"
  //               flexDirection={{ xs: 'column', sm: 'row' }}
  //               alignItems={{ xs: 'stretched', sm: 'center' }}
  //               justifyContent={'space-between'}
  //               width={1}
  //               marginBottom={2}
  //             >
  //               <Box marginBottom={{ xs: 1, sm: 0 }}>
  //                 <Typography variant={'subtitle2'}>
  //                   Enter your password
  //                 </Typography>
  //               </Box>
  //               {/* <Typography variant={'subtitle2'}>
  //                 <Link
  //                   component={'a'}
  //                   color={'primary'}
  //                   href={'/password-reset'}
  //                   underline={'none'}
  //                 >
  //                   Forgot your password?
  //                 </Link>
  //               </Typography> */}
  //             </Box>
  //             {/* <TextField
  //               label="Password *"
  //               variant="outlined"
  //               name={'password'}
  //               type={'password'}
  //               fullWidth
  //               value={formik.values.password}
  //               onChange={formik.handleChange}
  //               error={formik.touched.password && Boolean(formik.errors.password)}
  //               helperText={formik.touched.password && formik.errors.password}
  //             /> */}
  //             <TextInput name="password" label="Password" type="password" />
  //           </Grid>
  //           <Grid item container xs={12}>
  //             <Box
  //               display="flex"
  //               flexDirection={{ xs: 'column', sm: 'row' }}
  //               alignItems={{ xs: 'stretched', sm: 'center' }}
  //               justifyContent={'space-between'}
  //               width={1}
  //               maxWidth={600}
  //               margin={'0 auto'}
  //             >
  //               <Box marginBottom={{ xs: 1, sm: 0 }}>
  //                 {/* <Typography variant={'subtitle2'}>
  //                   Don't have an account yet?{' '}
  //                   <Link
  //                     component={'a'}
  //                     color={'primary'}
  //                     href={'/signup-cover'}
  //                     underline={'none'}
  //                   >
  //                     Sign up here.
  //                   </Link>
  //                 </Typography> */}
  //                 <Typography variant={'subtitle2'}>
  //                   <Link
  //                     component={'a'}
  //                     color={'primary'}
  //                     href={'/password-reset'}
  //                     underline={'none'}
  //                   >
  //                     Forgot your password?
  //                   </Link>
  //                 </Typography>
  //               </Box>
  //               <Button size={'large'} variant={'contained'} type={'submit'}>
  //                 Login
  //               </Button>
  //             </Box>
  //           </Grid>
  //         </Grid>
  //       </form>
  //     </Box>
  //   </FormProvider>

  // );
}

export default Form;
