// import { privateApi } from '@/api/api';
import { jwtDecode } from 'jwt-decode';
import useAuth from './useAuth';
import axios from 'axios';
import { AccessTokenPayload } from './AuthContext';

const useRefreshToken = () => {
    const { setAuth, logout } = useAuth();

    const refresh = async () => {
        try {
            const response = await axios.get('/public/auth/refresh', {
                baseURL: import.meta.env.VITE_API_BASE_URL,
                withCredentials: true
            });
    
            // get access token and role from response
            const { accessToken } = response.data;
    
            // get data from access token
            const { userId, role } = jwtDecode<AccessTokenPayload>(accessToken);
    
            // update auth context
            setAuth((prev) => ({
                ...prev,
                accessToken: accessToken,
                user: {
                    id: userId,
                    role: role,
                }
            }));
            
            return response.data.accessToken;
            
        } catch (error) {
            if (error.response && error.response.status === 401) {
                await logout({
                    message: 'Your session has expired.',
                    level: 'warning',
                });
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              console.log(error.config);
        }
    }
    return refresh;
};

export default useRefreshToken;