import dayjs from "dayjs";
import { z } from "zod";
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(isSameOrBefore)

export const UpdateCoursePeriodFormSchema = z.object({
    schedule_type: z.enum(['self_scheduled', 'fixed', 'manual', 'no_schedule']).readonly(),
    section_id: z.string()
        .min(1, 'Section is required')
        .default(''),
    capacity: z.coerce.number()
        .min(1, "Registration limit must be greater than 0"),
    date: z.string()
        .min(1, "Date is required")
        .refine(date => dayjs(date, 'YYYY-MM-DD').isValid(), 'Date is not formatted correctly'),
    start: z.string({ required_error: "Start time is required" })
        .refine(date => dayjs(date).isValid(), 'Start time is not formatted correctly'),
    end: z.string({ required_error: "End time is required" })
        .refine(date => dayjs(date).isValid(), 'End time is not formatted correctly'),
    open_enrollment: z.string()
        .refine(date => dayjs(date).isValid(), 'Open enrollment is not formatted correctly'),
    close_enrollment: z.string()
        .refine(date => dayjs(date).isValid(), 'Close enrollment is not formatted correctly'),
    instructor: z.string()
        .uuid()
})

    // end time must be after start time
    .refine((value) => dayjs(value.end).isAfter(value.start), {
        path: ['end'],
        message: 'End time must be after start time',
    })

    // open enrollment is required for self scheduled courses only
    .refine((value) => {
        if (value.schedule_type !== 'self_scheduled') {
            value.open_enrollment = '';
            return true;
        }
        if (!value.open_enrollment) {
            return false;
        }
        return true;
    }, {
        path: ['open_enrollment'],
        message: 'Open enrollment is required for student scheduled coures',
    })

    // close enrollment is required for self scheduled courses only
    .refine((value) => {
        if (value.schedule_type !== 'self_scheduled') {
            value.close_enrollment = '';
            return true;
        }
        if (!value.close_enrollment) {
            return false;
        }
        return true;
    }, {
        path: ['close_enrollment'],
        message: 'Close enrollment is required for student scheduled coures',
    })

    // close enrollment must be after open enrollment
    .refine((value) => {
        if (!value.close_enrollment || dayjs(value.close_enrollment).isAfter(value.open_enrollment)) {
            return true;
        }
        return false;
    }, {
        path: ['close_enrollment'],
        message: 'Close enrollment must be after open enrollment',
    })

    // close enrollment must be the same or before start time
    .refine((value) => (!value.close_enrollment || (!!value.close_enrollment && dayjs(value.close_enrollment).isSameOrBefore(value.start))), {
        path: ['close_enrollment'],
        message: 'Close enrollment must be must be the same or before start time',
    });

export type UpdateCoursePeriodFormValues = z.infer<typeof UpdateCoursePeriodFormSchema>;
