import { FieldArrayPath, useFieldArray, useFormState, useWatch } from "react-hook-form";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import SelectInput from '@/components/FormInputs/SelectInput';
import FormattedInput from "@/components/FormInputs/FormattedInput.tsx";
import TextInput from "@/components/FormInputs/TextInput.tsx";
import { InputAdornment } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Fragment } from "react";
import { StudentInfoFormValues } from "@/components/users/Students/Account/Tabs/Profile/formSchema";
import { Relations } from "@/components/Forms/Constants";
import { v4 as uuidv4 } from 'uuid';

interface PropTypes {
    name: FieldArrayPath<StudentInfoFormValues>;
}

function ParentFieldArray({ name }: PropTypes) {
    const fieldArray = useFieldArray({ name: name });
    const parentValues = useWatch({ name: name });
    const { errors } = useFormState<StudentInfoFormValues>({
        name: name
    });

    return (
        <Grid container flexDirection="row" spacing="15px">
            {fieldArray.fields.map((_field, index) => (

                <Fragment key={index}>

                    <Grid item xs={12}>
                        <Typography
                            variant='h5'
                            sx={{
                                fontWeight: 'bold',
                                textDecoration: 'underline'
                            }}
                        >
                            Parent {index + 1}
                        </Typography>
                        <Typography color="error" fontWeight="bold">
                            {errors[name]?.[index]?.root?.message}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <SelectInput
                            name={`${name}.${index}.relation`}
                            label="Relation"
                            options={Relations}
                            sx={{
                                minWidth: '100px'
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextInput
                            name={`${name}.${index}.first_name`}
                            label="First Name"
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextInput
                            name={`${name}.${index}.last_name`}
                            label="Last Name"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextInput
                            name={`${name}.${index}.email`}
                            label="Email"
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <FormattedInput
                            name={`${name}.${index}.cell_phone`}
                            label="Cell Phone"
                            format="(###) ###-####"
                            mask="_"
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <FormattedInput
                            name={`${name}.${index}.home_phone`}
                            label="Home Phone"
                            format="(###) ###-####"
                            mask="_"
                        />
                    </Grid>

                    <Box width="100%" />

                    <Grid item xs={12} md={4}>
                        <FormattedInput
                            name={`${name}.${index}.work_phone`}
                            label="Work Phone"
                            format="(###) ###-####"
                            mask="_"
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextInput
                            name={`${name}.${index}.work_extension`}
                            label="Work Extension"
                            disabled={!parentValues?.[index]?.work_phone}
                            InputProps={{ startAdornment: <InputAdornment position="start">Ext.</InputAdornment> }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            size="small"
                            color="error"
                            fullWidth
                            onClick={() => {
                                console.log(`Deleting index: ${index}`);
                                fieldArray.remove(index)
                            }}
                        >
                            Remove Parent {index + 1}
                        </Button>
                    </Grid>

                </Fragment>

            ))}

            {fieldArray.fields.length === 0 && (
                <Grid item xs={12}>
                    <Typography
                        variant='h5'
                        sx={{
                            fontWeight: 'bold',
                            textDecoration: 'underline'
                        }}
                    >
                        No Parents
                    </Typography>
                </Grid>
            )}

            <Grid item xs={12}>
                <Button
                    variant="contained"
                    size="small"
                    fullWidth
                    onClick={() => {
                        fieldArray.append({
                            id: uuidv4(),
                            first_name: '',
                            last_name: '',
                            relation: '',
                            email: '',
                            cell_phone: '',
                            home_phone: '',
                            work_phone: '',
                            work_extension: '',
                        });
                    }}
                >
                    Add Parent
                </Button>
            </Grid>
        </Grid>
    );
}

export default ParentFieldArray;