import { useEffect } from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import AdminLayout from '@/layouts/admin';
import InstructorLayout from '@/layouts/instructor';
import theme from '@/assets/theme';
import { useSoftUIController, setOpenConfigurator } from '@/context';
import PersistLogin from './components/PersistLogin';
import RequireAuth from './components/RequireAuth';
import SigninCover from './layouts/auth/SigninCover/SigninCover';

export default function App() {
    const [controller, dispatch] = useSoftUIController();
    const {
        direction,
        openConfigurator,
    } = controller;
    const { pathname } = useLocation();


    // useEffect(() => {

    //     const requestIntercept = axios.interceptors.request.use(
    //         (config) => {
    //             const accessToken = auth?.accessToken;
    //             // if (!config.headers['Authorization']) {
    //             //     config.headers['Authorization'] = `Bearer ${accessToken}`;
    //             // }
    //             config.headers['Authorization'] = `Bearer ${accessToken}`;
    //             return config;
    //         }, (error) => Promise.reject(error)
    //     );

    //     const responseIntercept = axios.interceptors.response.use(
    //         response => response,
    //         async (error) => {
    //             const prevRequest = error?.config;
    //             if (error?.response?.status === 403 && !prevRequest?.sent) {
    //                 prevRequest.sent = true;
    //                 const newAccessToken = await refresh();
    //                 prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
    //                 return axios(prevRequest);
    //             }
    //             return Promise.reject(error);
    //         }
    //     );

    //     return () => {
    //         axios.interceptors.request.eject(requestIntercept);
    //         axios.interceptors.response.eject(responseIntercept);
    //     }
    // }, [auth, refresh])

    // Change the openConfigurator state
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

    // Setting the dir attribute for the body element
    useEffect(() => {
        document.body.setAttribute('dir', direction);
    }, [direction]);

    // Setting page scroll to 0 when changing the route
    useEffect(() => {
        // document.documentElement.scrollTop = 0;
        // document.scrollingElement.scrollTop = 0;
    }, [pathname]);

    // useEffect(() => {
    //     console.log(`Auth Status is now: ${authStatus}`);
    // }, [authStatus]);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Routes>
                {/* {authStatus !== 'configuring' && ( */}
                {/* <> */}
                {/* <Route path="/admin/*" element={<ProtectedRoute allowedRoles={['Admin']}><AdminLayout /></ProtectedRoute>} key="AdminLayout"/> */}
                {/* <Route path="/admin/" element={<PersistLogin />} key="AdminLayout">
                           <Route path='*' element={<AdminLayout />} />
                            </Route>
                        <Route path="/instructor/*" element={<InstructorLayout />} key="InstructorLayout"/>
                        <Route path="*" element={<Navigate to="/auth/sign-in" />} /> */}
                {/* </> */}
                {/* )} */}
                {/* <Route path="/auth/sign-in" element={<SignInBasic />} key="basic" /> */}
                <Route path="/auth/sign-in" element={<SigninCover />} key="basic" />
                {/* <Route path="/auth/sign-in" element={<div>
                    You have been logged out.
                </div>} key="basic" /> */}


                <Route element={<PersistLogin />}>
                    <Route element={<RequireAuth allowRoles={['ADMIN']} />}>
                        <Route path="/admin/*" element={<AdminLayout />} key="AdminLayout" />
                    </Route>
                    <Route element={<RequireAuth allowRoles={['INSTRUCTOR']} />}>
                        <Route path="/instructor/*" element={<InstructorLayout />} key="InstructorLayout" />
                    </Route>
                    <Route path="*" element={<Navigate to="/auth/sign-in" />} />
                </Route>


            </Routes>

        </ThemeProvider>
    );
}
