import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { StudentInfoFormValues, studentInfoFormSchema } from './formSchema';
import Stack from '@mui/material/Stack';
import {HighSchools} from "@/components/Forms/Constants.ts";
import { useGetStudent } from '@/api/users/students/GetStudent';
import { useUpdateStudent } from '@/api/users/students/UpdateStudent';
import CircularProgress from '@mui/material/CircularProgress';
import { AxiosError } from 'axios';
import { ErrorResponse } from '@/api/api';
import useFeedbackDialog from '@/components/useFeedbackDialog';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// Form Input Components
import TextInput from '@/components/FormInputs/TextInput';
import SelectInput from '@/components/FormInputs/SelectInput';
import DateInput from '@/components/FormInputs/DateInput';
import PhoneFieldArray from '@/components/FormInputs/FieldArrays/PhoneFieldArray';
import AddressFieldArray from '@/components/FormInputs/FieldArrays/AddressFieldArray';
import ParentFieldArray from '@/components/FormInputs/FieldArrays/ParentFieldArray';
import Card from '@mui/material/Card';
import { PhoneSchemaValues } from '@/validationSchemas/phone';



interface PropTypes {
    studentId: string
}


function StudentInfo({ studentId }: PropTypes): JSX.Element {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const {openFeedbackDialog, FeedbackDialog} = useFeedbackDialog();

    const {data: user} = useGetStudent({
        variables: {
            id: studentId,
        },
    });

    const updateStudent = useUpdateStudent({
        onSuccess: (data) => {
            if (data) {
                // queryClient.invalidateQueries({ queryKey: queryKey }).then(() => {
                //     form.reset(getFormValues(user));
                // });
                openFeedbackDialog({
                    icon: 'success',
                    title: 'Your information has been updated.',
                });
            }
        },
        onError(error: AxiosError<ErrorResponse>) {
            console.log(error)
            openFeedbackDialog({
                title: 'Something went wrong.',
                message: error?.response?.data?.message ?? error.message
            });
        },
    });

    const form = useForm<StudentInfoFormValues>({
        resolver: zodResolver(studentInfoFormSchema),
        mode: 'all',
        values: {
            first_name: user?.first_name ?? '',
            middle_name: user?.middle_name ?? '',
            last_name: user?.last_name ?? '',
            nickname: user?.nickname ?? '',
            email: user?.email ?? '',
            date_of_birth: user?.date_of_birth ?? '',
            sex: (user?.sex as 'male' | 'female'),

            permit_num: user?.student?.permit_num ?? '',
            permit_issued: user?.student.permit_issued ?? '',
            permit_expiration: user?.student?.permit_expiration ?? '',
            permit_restrictions: user?.student?.permit_restrictions ?? '',
            permit_endorsements: user?.student?.permit_endorsements ?? '',
            medical_conditions: user?.student?.medical_conditions ?? '',
            high_school: user?.student?.high_school ?? '',
            user_parents: user?.student?.parents.map((parent) => ({
                id: parent.id,
                first_name: parent?.first_name ?? '',
                last_name: parent?.last_name ?? '',
                relation: parent?.relation ?? '',
                email: parent?.email ?? '',
                cell_phone: parent?.cell_phone ?? '',
                home_phone: parent?.home_phone ?? '',
                work_phone: parent?.work_phone ?? '',
                work_extension: parent?.work_extension ?? '',
            })) ?? [],

            user_addresses: user?.user_addresses.map((address) => ({
                priority: address.priority,
                name: address?.name ?? '',
                street_address: address?.street_address ?? '',
                apt_no: address?.apt_no ?? '',
                po_box_no: address?.po_box_no ?? '',
                city: address?.city ?? '',
                state: address?.state ?? '',
                zip: address?.zip ?? '',
                country: address?.country ?? '',
            })) ?? [],
            user_phones: user?.user_phones.map((phone) => ({
                priority: phone.priority,
                name: phone?.name ?? '',
                type: (phone?.type as "mobile" | "home" | "work" | "fax" | "other") ?? '' as PhoneSchemaValues['type'],
                phone: phone?.phone ?? '',
                extension: phone?.extension ?? '',
            })) ?? []
        }
    });

    const { handleSubmit, formState: { errors } } = form;

    const onSubmit = async (data: StudentInfoFormValues) => {
        console.log('Updating student information');
        console.log(JSON.stringify(data, null, 2));

        if (!studentId) {
            return;
        }

        updateStudent.mutateAsync({
            id: studentId,
            formValues: data,
        });
    };

    if (!user) {
        return (
            <Box display="flex" justifyContent="center">
                <CircularProgress />
            </Box>
        )
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
            <Card sx={{ boxShadow: 3, padding: 2 }}>
            <Box>
            {FeedbackDialog}
                <FormProvider {...form}>
                    <Stack
                        component={'form'}
                        onSubmit={handleSubmit(onSubmit)}
                        gap={8}
                        sx={{
                            '& .MuiOutlinedInput-root.MuiInputBase-multiline': {
                                padding: 0,
                            },
                            '& .MuiOutlinedInput-input': {
                                background: theme.palette.background.paper,
                                padding: 2,
                            },
                        }}
                    >


                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography sx={{ fontWeight: 'bold', textDecoration: 'underline' }} variant='h5'>Student Information</Typography>
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <TextInput name="first_name" label="First Name" />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextInput name="middle_name" label="Middle Name" />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextInput name="last_name" label="Last Name" />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <TextInput name="nickname" label="Nickname" />
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <TextInput name="email" label="Student Email" />
                            </Grid>

                            <Grid item xs={12} md={2}>
                                <SelectInput
                                    name="sex"
                                    label="Sex"
                                    options={[
                                        { label: 'Male', value: 'male' },
                                        { label: 'Female', value: 'female' },
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <DateInput
                                    name="date_of_birth"
                                    disableFuture
                                    label="Date of Birth"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <SelectInput
                                    name="high_school"
                                    label="High School"
                                    options={HighSchools}
                                    fullWidth
                                />
                            </Grid>

                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography sx={{ fontWeight: 'bold', textDecoration: 'underline' }} variant='h5'>Permit Information</Typography>
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <TextInput
                                    name="permit_num"
                                    label="Permit Number"
                                    helperText="Enter exactly as displayed including dashes."
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <DateInput
                                    name="permit_issued"
                                    disableFuture
                                    label="Permit Issue Date"
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <DateInput
                                    name="permit_expiration"
                                    disablePast
                                    label="Permit Expiration Date"
                                />
                            </Grid>


                        </Grid>

                        <PhoneFieldArray name="user_phones" />


                        <AddressFieldArray name="user_addresses" />

                        <ParentFieldArray name="user_parents" />

                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            gap={4}
                        >
                            {/* <Typography color="error">{JSON.stringify(errors)}</Typography> */}
                            {errors.user_phones?.root && (
                                <Typography color="error" fontWeight="bold">{errors.user_phones?.root?.message}</Typography>
                            )}
                            {errors.user_addresses?.root && (
                                <Typography color="error" fontWeight="bold">{errors.user_addresses?.root?.message}</Typography>
                            )}
                            {errors.user_parents?.root && (
                                <Typography color="error" fontWeight="bold">{errors.user_parents?.root?.message}</Typography>
                            )}
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                size="large"
                                fullWidth={!isMd}
                            // disabled={isPending}
                            >
                                <Typography variant="inherit" fontWeight="bold">
                                    Update Information
                                </Typography>
                            </Button>
                        </Box>

                    </Stack>

                </FormProvider>


            </Box>
            </Card>
        </LocalizationProvider>
    );
}

export default StudentInfo;
