import {Chip} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

interface PropTypes {
    attendance: Attendance
}
export default function AttendanceChip({attendance}: PropTypes) {
    switch (attendance) {
        case 'present':
            return (
                <Chip
                    sx={{width: '100%!important', fontWeight: 'bold'}}
                    icon={<CheckIcon fontSize="medium"/>}
                    size="small"
                    label="Present"
                    color="success"
                    variant="outlined"
                />
            );
        case 'absent':
            return (
                <Chip
                    sx={{width: '100%!important', fontWeight: 'bold'}}
                    icon={<CloseIcon fontSize="medium"/>}
                    size="small"
                    label="No-Show"
                    color="error"
                    variant="outlined"
                />
            );
        case 'no_permit':
            return (
                <Chip
                    sx={{width: '100%!important', fontWeight: 'bold'}}
                    icon={<CloseIcon fontSize="medium"/>}
                    size="small"
                    label="No Permit"
                    color="warning"
                    variant="outlined"
                />
            );
        default:
            return null;
    }
}