/* eslint-disable no-unused-vars */
/**
 =========================================================
 * Soft UI Dashboard PRO React - v3.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// Soft UI Dashboard PRO React Base Styles
// import colors from '@/assets/theme/base/colors';
import borders from '@/assets/theme/base/borders';

// Soft UI Dashboard PRO helper functions

const {
  borderWidth,
  borderRadius,
} = borders;

export default {
  styleOverrides: {
    root: {
      display: 'flex !important',
      // padding: `${pxToRem(8)} ${pxToRem(28)} ${pxToRem(8)} ${pxToRem(12)} !important`,
      // border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
      // borderRadius: `${borderRadius.md} !important`,
      //
      // '& fieldset': {
      //   border: 'none',
      // },
    },

    input: {
      // height: pxToRem(22),
      // width: 'max-content !important',
    },

    inputSizeSmall: {
      // height: pxToRem(14),
    },
  },
};
