import addressSchema from "@/validationSchemas/address";
import parentSchema from "@/validationSchemas/parent";
import phoneSchema from "@/validationSchemas/phone";
import { z } from "zod";

const studentInfoFormSchema = z.object({
    first_name: z.string()
        .min(1, "First name is required")
        .max(50, 'Please enter a valid first name')
        .default(''),
    middle_name: z.string({ required_error: "Middle name is required" })
        .min(1, "Middle name is required")
        .max(50, 'Please enter a valid middle name')
        .default(''),
    last_name: z.string()
        .min(1, "Last name is required")
        .max(50, 'Please enter a valid last name')
        .default(''),
    nickname: z.string()
        .max(50, 'Please enter a valid nickname')
        .optional(),
    email: z.string({ required_error: "Email is required" })
        .email('Please enter a valid email address'),
    sex: z.enum(['male', 'female']),
    date_of_birth: z.string({ required_error: "Date of birth is required" })
        .refine((date) => (new Date(date) >= new Date('1900-01-01')), 'Date of Birth is invalid'),
    high_school: z.string({ required_error: "High school is required" }),
    medical_conditions: z.string()
        .optional(),
    permit_num: z.string()
        .optional(),
    permit_issued: z.string()
        .optional()
        .refine((date) => (!date || (new Date(date) <= new Date())), 'Permit issue date is invalid'),
    permit_expiration: z.string()
        .optional()
        .refine((date) => (!date || (new Date(date) > new Date())), 'Permit expiration date is invalid'),
    permit_restrictions: z.string()
        .optional(),
    permit_endorsements: z.string()
        .optional(),
    user_phones: z.array(phoneSchema)
        .min(1, "At least 1 phone number is required"),
    user_addresses: z.array(addressSchema)
        .min(1, "At least 1 address is required"),
    user_parents: z.array(parentSchema)
})

    // determine if student is under 18 and if so require at least one parent
    .refine((data) => {
        // calculate age based on date_of_birth
        const today = new Date();
        const age = today.getFullYear() - new Date(data.date_of_birth).getFullYear();

        // check if the user is under 18
        const isUnder18 = age < 18;

        // if under 18, ensure that the objectArray has at least one item
        return !isUnder18 || (isUnder18 && data.user_parents.length >= 1);
    }, {
        path: ['user_parents.root'],
        message: 'At least 1 parent is required for students under 18',
    })

    // determine if user provided permit number and if so require issue date
    .refine((data) => (!data.permit_num || data.permit_issued), {
        path: ['permit_issued'],
        message: 'Permit issue date is required when a permit number is provided.',
    })

    // determine if user provided permit number and if so require expiration date
    .refine((data) => (!data.permit_num || data.permit_expiration), {
        path: ['permit_expiration'],
        message: 'Permit expiration date is required when a permit number is provided.',
    })

    // determine if user provided any permit information and if so require permit number
    .refine((data) => (data.permit_num || !(data.permit_expiration || data.permit_issued || data.permit_endorsements || data.permit_restrictions)), {
        path: ['permit_num'],
        message: 'Permit number is required when any other permit information is provided.',
    })

    // convert dates to string before form submission
    .refine((data) => {

        if (data.date_of_birth) {
            data.date_of_birth = new Date(data.date_of_birth).toISOString().slice(0, 10);
        }

        if (data.permit_issued) {
            data.permit_issued = new Date(data.permit_issued).toISOString().slice(0, 10);
        }

        if (data.permit_expiration) {
            data.permit_expiration = new Date(data.permit_expiration).toISOString().slice(0, 10);
        }
        return true;

    });

export type StudentInfoFormValues = z.infer<typeof studentInfoFormSchema>;
export { studentInfoFormSchema };