import { useEffect, useState } from 'react';

import {
  useLocation,
} from 'react-router-dom';

import AdminProvider from './AdminProvider.tsx';
import routes from './routes';
import brand from '@/assets/images/icon.png';
import Sidenav from '@/components/Sidenav';
import {
  setMiniSidenav,
  useSoftUIController,
} from '@/context/index.jsx';

export default function AdminLayout() {
  const [controller, dispatchSBM] = useSoftUIController();
  const {
    miniSidenav,
    direction,
    sidenavColor,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatchSBM, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatchSBM, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute('dir', direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    // document.documentElement.scrollTop = 0;
    // document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  return (
    <>
      <Sidenav
        color={sidenavColor}
        brand={brand}
        brandName="DrivingDoc Admin"
        routes={routes}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      />
      <AdminProvider />
    </>
  );
}
