import React, { useState } from 'react';
import sidebarRoutes from './sidebarRoutes';
import brand from '@/assets/images/icon.png';
import Sidenav from '@/components/Sidenav';
import {
    setMiniSidenav,
    useSoftUIController,
} from '@/context/index.jsx';
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import InstructorRoutes from "@/layouts/instructor/InstructorRoutes";

const client = new QueryClient();

const ReactQueryDevtoolsProduction = React.lazy(() =>
    import('@tanstack/react-query-devtools/build/lib/index.prod.js').then(
        (d) => ({
            default: d.ReactQueryDevtools,
        }),
    ),
)

export default function InstructorLayout() {
    const [controller, dispatchSBM] = useSoftUIController();
    const {
        miniSidenav,
        // direction,
        sidenavColor,
    } = controller;
    const [onMouseEnter, setOnMouseEnter] = useState(false);
    // const { pathname } = useLocation();

    // Open sidenav when mouse enter on mini sidenav
    const handleOnMouseEnter = () => {
        if (miniSidenav && !onMouseEnter) {
            setMiniSidenav(dispatchSBM, false);
            setOnMouseEnter(true);
        }
    };

    // Close sidenav when mouse leave mini sidenav
    const handleOnMouseLeave = () => {
        if (onMouseEnter) {
            setMiniSidenav(dispatchSBM, true);
            setOnMouseEnter(false);
        }
    };

    // Setting the dir attribute for the body element
    // useEffect(() => {
    //     document.body.setAttribute('dir', direction);
    // }, [direction]);

    // Setting page scroll to 0 when changing the route
    // useEffect(() => {
    //     // document.documentElement.scrollTop = 0;
    //     // document.scrollingElement.scrollTop = 0;
    // }, [pathname]);

    return (
        <>
            <Sidenav
                color={sidenavColor}
                brand={brand}
                brandName="DrivingDoc Instructor"
                routes={sidebarRoutes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
            />
            <QueryClientProvider client={client}>
                <InstructorRoutes />
                {process.env.NODE_ENV === 'development' && (
                    <React.Suspense fallback={null}>
                        <ReactQueryDevtoolsProduction />
                    </React.Suspense>
                )}
            </QueryClientProvider>
        </>
    );
}
