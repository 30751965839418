// Soft UI Dashboard PRO React layouts
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
// import GroupIcon from '@mui/icons-material/Group';
import SchoolIcon from '@mui/icons-material/School';

// import Scheduler from './scheduling/Entry/Scheduler';
// import MasterCalendar from './scheduling/Master-Calendar';
import MasterCalendarV3 from '../../components/scheduling/Master-CalendarV3';
import CourseManagementDashboard from "../../components/courses";
// import RegistrationLog from './scheduling/Registrations';
// import Instructors from './users/Instructors';
// import InstructorsV2 from './users/Instructors/indexv2';
// import Students from './users/Students';

const routes = [
  {
    type: 'collapse',
    name: 'Scheduling',
    key: 'scheduling',
    icon: <CalendarTodayIcon/>,
    collapse: [
      {
        name: 'Master Calendar',
        key: 'calendar',
        route: 'scheduling/master-calendar',
        component: MasterCalendarV3,
      },
    ],
  },
  {
    type: 'collapse',
    name: 'Courses',
    key: 'courses',
    icon: <SchoolIcon />,
    collapse: [
      {
        name: 'Manage Courses',
        key: 'courses',
        route: 'courses',
        component: CourseManagementDashboard,
      },
    ],
  },
];

export default routes;
