/* eslint-disable no-unused-vars */
/**
 =========================================================
 * Soft UI Dashboard PRO React - v3.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// Soft UI Dashboard PRO React base styles
import borders from '@/assets/theme/base/borders';
import colors from '@/assets/theme/base/colors';
import typography from '@/assets/theme/base/typography';

// Soft UI Dashboard PRO React helper functions
import pxToRem from '@/assets/theme/functions/pxToRem';

const {
  light,
  text,
  dark,
} = colors;
const { borderRadius } = borders;
const { size } = typography;

export default {
  styleOverrides: {
    root: {
      // minWidth: pxToRem(160),
      // minHeight: 'unset',
      // padding: `${pxToRem(4.8)} ${pxToRem(16)}`,
      padding: `${pxToRem(6)} ${pxToRem(8)} ${pxToRem(6)} ${pxToRem(32)}`,
      borderRadius: borderRadius.sm,
      fontSize: size.sm,
      // color: text.main,
      // transition: 'background-color 300ms ease, color 300ms ease',
      //
      '&:hover, &:focus, &.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus': {
        backgroundColor: light.main,
        color: dark.main,
      },
    },
  },
};
