import { useFieldArray } from "react-hook-form";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import SelectInput from "@/components/FormInputs/SelectInput";
import TextInput from "@/components/FormInputs/TextInput";
import { useMediaQuery, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Fragment } from "react";
import { States } from "@/components/Forms/Constants";

interface PropTypes {
    name: string
}

function AddressFieldArray({ name }: PropTypes) {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });
    const fieldArray = useFieldArray({ name: name });

    return (
        <Grid container rowSpacing={2} columnSpacing={isMd ? 4 : 2}>
            {fieldArray.fields.map((_field, index) => (
                <Fragment key={index}>

                    <Grid item xs={12}>
                        <Typography
                            variant='h5'
                            sx={{
                                fontWeight: 'bold',
                                textDecoration: 'underline'
                            }}
                        >
                            Address {index + 1}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <TextInput
                            name={`${name}.${index}.street_address`}
                            label="Street Address"
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextInput
                            name={`${name}.${index}.city`}
                            label="City"
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <SelectInput
                            name={`${name}.${index}.state`}
                            label="State"
                            options={States}
                            sx={{
                                minWidth: '100px'
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextInput
                            name={`${name}.${index}.zip`}
                            label="Zip Code"
                        />
                    </Grid>

                    {index > 0 && (
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                size="small"
                                color="error"
                                fullWidth
                                onClick={() => {
                                    console.log(`Deleting index: ${index}`);
                                    fieldArray.remove(index)
                                }}
                            >
                                Remove Address {index + 1}
                            </Button>
                        </Grid>
                    )}
                </Fragment>
            ))}

            {fieldArray.fields.length === 0 && (
                <Grid item xs={12}>
                    <Typography
                        variant='h5'
                        sx={{
                            fontWeight: 'bold',
                            textDecoration: 'underline'
                        }}
                    >
                        No Addresses
                    </Typography>
                </Grid>
            )}

            <Grid item xs={12}>
                <Button variant="contained" size="small" fullWidth onClick={() => {
                    fieldArray.append({
                        priority: fieldArray.fields.length + 1,
                        name: '',
                        type: '',
                        phone: '',
                        extension: '',
                    });
                }}>Add Address</Button>
            </Grid>

        </Grid>
    );
}

export default AddressFieldArray;