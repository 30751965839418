import { ReactNode, useEffect, useState } from 'react';
import { Box, ListItemIcon, MenuItem, } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import QuickSearch from './QuickSearch';
import { setMiniSidenav, useSoftUIController, } from '@/context/index.jsx';
import * as Sentry from '@sentry/browser';
import { useGetUser } from "@/api/users/GetUser.ts";
import Typography from "@mui/material/Typography";
import { useGlobalContext } from "@/GlobalContext";
import { useQueryClient } from '@tanstack/react-query';
import useAuth from '@/context/useAuth';

interface PropTypes {
    title?: string | ReactNode,
    absolute?: boolean,
    light?: boolean,
    isMini?: boolean,
    children?: string | JSX.Element | JSX.Element[] | (() => JSX.Element)
}

type NavbarType = "absolute" | "sticky" | "static" | "fixed" | "relative";

function DashboardNavbar({ title = '', children, absolute = false, light = false, isMini = false }: PropTypes) {
    const navigate = useNavigate();
    const { auth, logout } = useAuth();
    const [navbarType, setNavbarType] = useState<NavbarType | undefined>();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [controller, dispatch] = useSoftUIController();
    const {
        miniSidenav, transparentNavbar, fixedNavbar, // openConfigurator,
    } = controller;
    const [openMenu, setOpenMenu] = useState<Element | null>(null);
    const { data: currentUser } = useGetUser({
        variables: {
            id: auth?.user?.id,
        },
        enabled: !!auth?.user?.id,
    });
    const queryClient = useQueryClient();

    useEffect(() => {
        // Setting the navbar type
        if (fixedNavbar) {
            setNavbarType('sticky');
        } else {
            setNavbarType('static');
        }

        // A function that sets the transparent state of the navbar.
        // function handleTransparentNavbar() {
        //   // setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
        // }

        /**
         The event listener that's calling the handleTransparentNavbar function when
         scrolling the window.
         */
        // window.addEventListener('scroll', handleTransparentNavbar);

        // Call the handleTransparentNavbar function to set the state with the initial value.
        // handleTransparentNavbar();

        // Remove event listener on cleanup
        // return () => window.removeEventListener('scroll', handleTransparentNavbar);
    }, [dispatch, fixedNavbar]);

    const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
    // const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
    const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
    const handleCloseMenu = () => setOpenMenu(null);

    const reportProblem = () => {
        Sentry.showReportDialog({
            eventId: Sentry.captureMessage('User Feedback'), // user: {
            //   name: undefined
            // },
            title: 'Report A Bug',
            subtitle: 'Please give a clear and concise description of what the bug is and provide steps to reproduce the behavior.',
            subtitle2: '',
            labelSubmit: 'Submit Feedback',
        });
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // Render the notifications menu
    const renderMenu = () => (<Menu
        anchorEl={openMenu}
        anchorReference={null}
        anchorOrigin={{
            vertical: 'bottom', horizontal: 'left',
        }}
        open={Boolean(openMenu)}
        onClose={handleCloseMenu}
        sx={{ mt: 2 }}
    >
        <MenuItem>No Notifications</MenuItem>
        {/* <NotificationItem */}
        {/*  image={<img src={team2} alt="person" />} */}
        {/*  title={['New message', 'from Laur']} */}
        {/*  date="13 minutes ago" */}
        {/*  onClick={handleCloseMenu} */}
        {/* /> */}
        {/* <NotificationItem */}
        {/*  image={<img src={logoSpotify} alt="person" />} */}
        {/*  title={['New album', 'by Travis Scott']} */}
        {/*  date="1 day" */}
        {/*  onClick={handleCloseMenu} */}
        {/* /> */}
        {/* <NotificationItem */}
        {/*  color="secondary" */}
        {/*  image={( */}
        {/*    <Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}> */}
        {/*      payment */}
        {/*    </Icon> */}
        {/*  )} */}
        {/*  title={['', 'Payment successfully completed']} */}
        {/*  date="2 days" */}
        {/*  onClick={handleCloseMenu} */}
        {/* /> */}
    </Menu>);

    return (
        <AppBar
            position={absolute ? 'absolute' : navbarType}
            sx={{ backgroundColor: '#ffffff' }}
        >
            <Toolbar>
                {miniSidenav && (
                    <Box>
                        <MenuOpenIcon onClick={handleMiniSidenav} />
                    </Box>
                )}

                {!miniSidenav && (
                    <MenuIcon onClick={handleMiniSidenav} />
                )}

                <Box flexGrow={1} px="16px" pt={1} >
                    {typeof title === 'string' && (
                        <Typography variant="h3" fontWeight="bold">{title}</Typography>
                    )}
                    {typeof title !== 'string' && (
                        <>{title}</>
                    )}
                </Box>

                {auth?.user?.role === "ADMIN" && (
                    <Box minWidth="300px">
                        <QuickSearch />
                    </Box>
                )}

                <Box>
                    <IconButton
                        size="large"
                        color="error"
                        // variant="contained"
                        onClick={reportProblem}
                    >
                        <ReportProblemIcon />
                    </IconButton>
                </Box>

                <Box>
                    <IconButton
                        size="large"
                        color="inherit"
                        // sx={navbarIconButton}
                        aria-controls="notification-menu"
                        aria-haspopup="true"
                        // variant="contained"
                        onClick={handleOpenMenu}
                    >
                        <NotificationsIcon />
                    </IconButton>
                    {renderMenu()}
                </Box>

                <Box>
                    <IconButton
                        onClick={handleClick}
                        size="large"
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <AccountCircleIcon />
                        <Box pl={1}>
                            <Typography
                                variant="subtitle2"
                                fontWeight="bold"
                                color="white"
                                align="left"
                                noWrap
                                lineHeight={1}
                            // sx={{pl: 0.5}}
                            >
                                {currentUser && (`${currentUser.nickname ?? currentUser.first_name} ${currentUser.last_name}`)}
                            </Typography>
                            <Typography
                                variant="subtitle2"
                                fontWeight="normal"
                                color="white"
                                align="left"
                                noWrap
                            // sx={{pl: 0.5}}
                            >
                                {currentUser && (`${currentUser.email ?? ''}`)}
                            </Typography>
                        </Box>
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                    >
                        <MenuItem onClick={async () => {
                            // Auth.signOut().then(() => {
                            //     queryClient.clear();
                            //     console.log('Signed Out, Navigate to sign in page');
                            //     setTimeout(() => {
                            //         navigate('/auth/sign-in', {replace: true});
                            //     }, 1000);
                            // });
                            await logout();
                            navigate('/');
                        }}
                        >
                            <ListItemIcon>
                                <LogoutIcon />
                            </ListItemIcon>
                            Logout
                        </MenuItem>
                    </Menu>
                </Box>



                {/*<SuiBox*/}
                {/*    color="inherit"*/}
                {/*    mb={{*/}
                {/*        xs: 1, md: 0,*/}
                {/*    }}*/}
                {/*    sx={(theme) => navbarRow(theme, {isMini})}*/}
                {/*>*/}
                {/*     /!*<Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} /> *!/*/}
                {/*    /!*<Icon fontSize="medium" sx={navbarDesktopMenu} onClick={handleMiniSidenav}>*!/*/}
                {/*    /!*  {miniSidenav ? 'menu_open' : 'menu'}*!/*/}
                {/*    /!*</Icon>*!/*/}

                {/*    {miniSidenav ? <MenuOpenIcon onClick={handleMiniSidenav}/> :*/}
                {/*        <MenuIcon onClick={handleMiniSidenav}/>}*/}
                {/*</SuiBox>*/}

                {/*{isMini ? null : (*/}
                {/*    <SuiBox sx={(theme) => navbarRow(theme, {isMini})}>*/}
                {/*        <SuiBox pr={1}>*/}
                {/*            <Box width="300px">*/}
                {/*                <QuickSearch/>*/}
                {/*            </Box>*/}
                {/*        </SuiBox>*/}
                {/*        <SuiBox color={light ? 'white' : 'inherit'}>*/}



                {/*<IconButton*/}
                {/*    onClick={handleClick}*/}
                {/*    size="large"*/}
                {/*    sx={{ml: 2}}*/}
                {/*    aria-controls={open ? 'account-menu' : undefined}*/}
                {/*    aria-haspopup="true"*/}
                {/*    aria-expanded={open ? 'true' : undefined}*/}
                {/*>*/}
                {/*    <AccountCircleIcon/>*/}
                {/*    <SuiTypography*/}
                {/*        variant="button"*/}
                {/*        fontWeight="medium"*/}
                {/*        color={light ? 'white' : 'dark'}*/}
                {/*        sx={{pl: 0.5}}*/}
                {/*    >*/}
                {/*        {currentUser && (`${currentUser.first_name} ${currentUser.last_name}`)}*/}
                {/*        /!*{`${userInfo.given_name} ${userInfo.family_name}`}*!/*/}
                {/*        /!*Logout*!/*/}
                {/*    </SuiTypography>*/}
                {/*</IconButton>*/}
                {/*<Menu*/}
                {/*    sx={{mt: 2}}*/}
                {/*    anchorEl={anchorEl}*/}
                {/*    open={open}*/}
                {/*    onClose={handleClose}*/}
                {/*    onClick={handleClose}*/}
                {/*    transformOrigin={{horizontal: 'center', vertical: 'top'}}*/}
                {/*    anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}*/}
                {/*>*/}
                {/*    <MenuItem onClick={() => {*/}
                {/*        Auth.signOut().then(() => {*/}
                {/*            history.push('/auth');*/}
                {/*        });*/}
                {/*    }}*/}
                {/*    >*/}
                {/*        <ListItemIcon>*/}
                {/*            <LogoutIcon/>*/}
                {/*        </ListItemIcon>*/}
                {/*        Logout*/}
                {/*    </MenuItem>*/}
                {/*</Menu>*/}
                {/*            <IconButton*/}
                {/*                size="large"*/}
                {/*                color="inherit"*/}
                {/*                // sx={navbarIconButton}*/}
                {/*                aria-controls="notification-menu"*/}
                {/*                aria-haspopup="true"*/}
                {/*                // variant="contained"*/}
                {/*                onClick={handleOpenMenu}*/}
                {/*            >*/}
                {/*                /!*<Icon className={light ? 'text-white' : 'text-dark'}>notifications</Icon>*!/*/}
                {/*                <NotificationsIcon/>*/}
                {/*            </IconButton>*/}
                {/*            {renderMenu()}*/}
                {/*            <IconButton*/}
                {/*                size="large"*/}
                {/*                color="error"*/}
                {/*                aria-controls="notification-menu"*/}
                {/*                aria-haspopup="true"*/}
                {/*                // variant="contained"*/}
                {/*                onClick={reportProblem}*/}
                {/*            >*/}
                {/*                <ReportProblemIcon/>*/}
                {/*            </IconButton>*/}
                {/*        </SuiBox>*/}
                {/*    </SuiBox>)}*/}
            </Toolbar>
            {children}
        </AppBar>);
}

// // Setting default values for the props of DashboardNavbar
// DashboardNavbar.defaultProps = {
//     absolute: false, light: false, isMini: false,
// };
//
// // Typechecking props for the DashboardNavbar
// DashboardNavbar.propTypes = {
//     absolute: PropTypes.bool, light: PropTypes.bool, isMini: PropTypes.bool,
// };

export default DashboardNavbar;
