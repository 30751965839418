import { z } from "zod";

const addressSchema = z.object({
    priority: z.number().positive().readonly(),
    street_address: z.string()
        .min(1, "Street address is required")
        .default(''),
    city: z.string()
        .min(1, "City is required")
        .default(''),
    state: z.string()
        .min(1, "State required")
        .default(''),
    zip: z.string().min(1, "Zip name is required")
        .regex(/^[0-9]+$/, 'Must be only digits')
        .length(5, 'Must be exactly 5 digits')
        .default('')
});

export type AddressSchemaValues = z.infer<typeof addressSchema>;
export default addressSchema