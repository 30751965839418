import phone from "phone";
import { z } from "zod";

const parentSchema = z.object({
    id: z.string().uuid(),
    relation: z.string(),
    first_name: z.string()
        .min(1, "First name is required")
        .max(50, 'Please enter a valid first name')
        .default(''),
    last_name: z.string()
        .min(1, "Last name is required")
        .max(50, 'Please enter a valid last name')
        .default(''),
    email: z.string({ required_error: "Email is required" })
        .email('Please enter a valid email address'),
    cell_phone: z.string()
        .refine(value => !value || phone(value).isValid, {
            message: 'Phone number is invalid',
        }),
    home_phone: z.string()
        .refine(value => !value || phone(value).isValid, {
            message: 'Phone number is invalid',
        }),
    work_phone: z.string()
        .refine(value => !value || phone(value).isValid, {
            message: 'Phone number is invalid',
        }),
    work_extension: z.string(),
}).refine(value => {
    if (!value.work_phone) {
        // remove work_extension when work_phone is falsy
        value.work_extension = '';
    }
    return true;
}).refine(value => !!value.cell_phone || !!value.home_phone || !!value.work_phone, {
    message: 'At least one phone number must be provided for each parent',
});

export type ParentSchemaValues = z.infer<typeof parentSchema>;
export default parentSchema;