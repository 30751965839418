/**
 =========================================================
 * Soft UI Dashboard PRO React - v3.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// Soft UI Dashboard PRO React base styles
import borders from '@/assets/theme/base/borders';
import colors from '@/assets/theme/base/colors';

// Soft UI Dashboard PRO React helper functions
import pxToRem from '@/assets/theme/functions/pxToRem';
import linearGradient from '@/assets/theme/functions/linearGradient';

const {
  borderWidth,
  borderColor,
} = borders;
const {
  transparent,
  gradients,
  info,
} = colors;

export default {
  styleOverrides: {
    root: {
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      width: pxToRem(20),
      height: pxToRem(20),
      marginRight: pxToRem(6),
      padding: 0,
      color: transparent.main,
      border: `${borderWidth[1]} solid ${borderColor}`,
      borderRadius: '50%',
      transition: 'all 250ms ease',

      '&:hover': {
        backgroundColor: transparent.main,
      },

      '& .MuiSvgIcon-root': {
        fill: transparent.main,
      },

      '&.Mui-focusVisible': {
        border: `${borderWidth[2]} solid ${info.main} !important`,
      },
    },

    colorPrimary: {
      backgroundColor: transparent.main,

      '&.Mui-checked': {
        backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='15px' width='15px'%3E%3Ccircle cx='50%' cy='50%' r='3' fill='%23fff' /%3E%3C/svg%3E"), ${linearGradient(
          gradients.dark.main,
          gradients.dark.state,
        )}`,
        borderColor: gradients.dark.main,
      },

      '&:hover': {
        backgroundColor: transparent.main,
      },
    },

    colorSecondary: {
      backgroundColor: transparent.main,

      '&.Mui-checked': {
        backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='15px' width='15px'%3E%3Ccircle cx='50%' cy='50%' r='3' fill='%23fff' /%3E%3C/svg%3E"), ${linearGradient(
          gradients.dark.main,
          gradients.dark.state,
        )}`,
        borderColor: gradients.dark.main,
      },

      '&:hover': {
        backgroundColor: transparent.main,
      },
    },
  },
};
