/**
 =========================================================
 * Soft UI Dashboard PRO React - v3.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useEffect } from 'react';

// react-router-dom components
import { useLocation } from 'react-router-dom';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// Soft UI Dashboard PRO React components
import SuiBox from '@/components/SuiBox';

// Soft UI Dashboard PRO React context
import { setLayout, useSoftUIController } from '@/context/index.jsx';

function DashboardLayout({ children }) {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, 'dashboard');
  }, [pathname]);

  return (
    <SuiBox
      sx={({
        breakpoints,
        transitions,
        functions: { pxToRem },
      }) => ({
        // py: 3,
        position: 'relative',

        [breakpoints.up('xl')]: {
          // marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
          marginLeft: miniSidenav ? pxToRem(96) : pxToRem(250),
          transition: transitions.create(['margin-left', 'margin-right'], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
    >
      {children}
    </SuiBox>
  );
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
