import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import useRefreshToken from "@/context/useRefreshToken";
import useAuth from "@/context/useAuth";
import { privateApi } from "@/api/api";

function PersistLogin() {
    const [isLoading, setIsLoading] = useState(true);
    const { auth, persist } = useAuth();
    const refresh = useRefreshToken();

    // check access token when component mounts 
    useEffect(() => {
        let isMounted = true;

        const verifyRefreshToken = async () => {
            let accessToken = null;
            try {
                accessToken = await refresh();
            }
            catch (err) {
                console.error(err);
            }
            finally {
                if (isMounted && !accessToken) {
                    setIsLoading(false);
                }
            }
        }
        
        // if there is no access token 
        if (!auth?.accessToken && persist) {
            // try to get an access token using the refresh token
            verifyRefreshToken();
        }

        return () => { isMounted = false };
    }, []);

    // checks if an auth token is present and sets up axios interceptors
    useEffect(() => {
        // store the id of each axios interceptor
        let requestInterceptorId: number;
        let responseInterceptorId: number;

        // when there is an access token
        if (auth?.accessToken) {
            // setup axios request interceptor to include the access token
            requestInterceptorId = privateApi.interceptors.request.use(
                (config) => {
                    const accessToken = auth?.accessToken;
                    if (!config.headers['Authorization'] && !accessToken) {
                        throw new Error("No Access Token Is Available");
                    } else if (!config.headers['Authorization']) {
                        config.headers['Authorization'] = `Bearer ${accessToken}`;
                    }
                    // config.headers['Authorization'] = `Bearer ${accessToken}`;
                    return config;
                }, (error) => Promise.reject(error)
            );
    
            // setup axios response interceptor to 
            // refresh the access token when it expires
            responseInterceptorId = privateApi.interceptors.response.use(
                response => response,
                async (error) => {
                    const prevRequest = error?.config;
                    if ((error?.response?.status === 401) && !prevRequest?.sent) {
                        prevRequest.sent = true;
                        const newAccessToken = await refresh();

                        console.log(`Tried to get a new access token: ${newAccessToken}`);

                        prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                        return privateApi(prevRequest);
                    }
                    return Promise.reject(error);
                }
            );

            setIsLoading(false);
        }

        // return () => {
        //     axios.interceptors.request.eject(requestInterceptorId);
        //     axios.interceptors.response.eject(responseInterceptorId);
        // }
    }, [auth, refresh]);

    if (!persist) {
        return (<Outlet />);
    }

    if (isLoading) {
        return null;
    }

    

    return (<Outlet />);
}

export default PersistLogin