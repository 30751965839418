import React from 'react';
import * as ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import {BrowserRouter} from 'react-router-dom';
import App from './App.tsx'
import { SoftUIControllerProvider } from '@/context';
import { GlobalContextProvider } from '@/GlobalContext';
import '@/index.css';
import {Amplify} from "aws-amplify";
import { Authenticator } from '@aws-amplify/ui-react';
import awsExports from '@/aws-exports';
import { AuthProvider } from './context/AuthContext.tsx';


Amplify.configure(awsExports);

Sentry.init({
    dsn: process.env.NODE_ENV === 'development' ? undefined : 'https://7de58eb95d9344389d973ce881aeff91@o997229.ingest.sentry.io/4505438920638464',
    integrations: [
        new Sentry.BrowserTracing({
            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.render(
    <Authenticator.Provider>
        <GlobalContextProvider>
        <AuthProvider>
            <Sentry.ErrorBoundary fallback={<div>An error has occurred</div>} showDialog>
                <BrowserRouter>
                    <SoftUIControllerProvider>
                        <App />
                    </SoftUIControllerProvider>
                </BrowserRouter>
            </Sentry.ErrorBoundary>
            </AuthProvider>
        </GlobalContextProvider>
    </Authenticator.Provider>,
    document.getElementById('root')
)
